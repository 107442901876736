<template>
  <div>
    <v-file-input
      @change="onFileChange"
      :value="selectedFiles"
      accept="image/jpeg, image/jpg, image/gif"
      clearable
      :multiple="multiple"
      :label="`Upload Picture${multiple ? 's' : ''}`"
    ></v-file-input>
    <v-btn v-if="hasValidCamera" @click="openCameraInput" width="100%" class="mb-1">
      <v-icon class="mr-1">mdi-camera</v-icon>
      Take Picture
    </v-btn>
    <input
      v-if="hasValidCamera"
      ref="cameraInput"
      type="file"
      accept="image/jpeg, image/jpg, image/gif"
      capture="camera"
      style="display: none;"
      @change="onCameraFileChange"
    />
    <div class="d-flex flex-wrap">
      <div
        v-for="(url, index) in imageUrls"
        :key="index"
        class="ma-1"
        style="max-width: calc(50% - 16px); width: calc(50% - 16px);"
      >
        <div class="d-flex flex-column">
          <v-img :src="url" aspect-ratio="1.7"></v-img>
          <div class="full-width d-flex justify-center">
            <v-btn icon @click="deleteImage(index)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import loadImage from 'blueimp-load-image';
export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      imageUrls: [],
      selectedFiles: [],
      hasValidCamera: false,
    }
  },
  computed: {
    ...mapState('app', [
      'isOffline',
    ]),
  },
  methods: {
    openCameraInput() {
      this.$refs.cameraInput.click();
    },
    async onCameraFileChange(event) {
      const files = event.target.files;
      await this.onFileChange(Array.from(files));
    },
    async onFileChange(files) {
      // if all files are in selectedFiles, do nothing
      if (files.every(file => this.selectedFiles.some(selectedFile => selectedFile.name === file.name && selectedFile.lastModified === file.lastModified))) {
        return;
      }
      this.imageUrls = [];
      if (!files || !files.length) {
        this.selectedFiles = []; // Clear existing files
        this.$emit('input', []);
        return;
      }
      const filteredFiles = [
        ...this.selectedFiles,
        ...files.filter(file => !this.selectedFiles.some(selectedFile => selectedFile.name === file.name && selectedFile.lastModified === file.lastModified)),
      ].filter(file => [
        'image/jpeg',
        'image/jpg',
        'image/gif',
      ].includes(file.type));

      if (filteredFiles.length) {
        this.imageUrls = await Promise.all(
          filteredFiles.map(file => this.readFile(file)),
        );
        this.selectedFiles = filteredFiles;
        const compressedFiles = []
        for (const file of filteredFiles) {
          const compressedImage = await this.compressImage(file)
          compressedFiles.push(compressedImage)
        }
        this.$emit('input', this.multiple ? compressedFiles : compressedFiles[0]);
      } else {
        this.$emit('input', []);
      }
    },
    readFile(file) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsDataURL(file);
      });
    },
    async compressImage(file) {
      try {
        const image = await this.loadImageFile(file);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const MAX_WIDTH = 500;
        const scaleFactor = MAX_WIDTH / image.width;
        canvas.width = MAX_WIDTH;
        canvas.height = image.height * scaleFactor;

        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
        let compressedDataUrl = canvas.toDataURL('image/jpeg');
        let byteSize = (compressedDataUrl.split(',')[1].length * 3) / 4;

        while (byteSize > 1 * 1024 * 1024) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
          compressedDataUrl = canvas.toDataURL('image/jpeg', 0.8);
          byteSize = (compressedDataUrl.split(',')[1].length * 3) / 4;
        }

        return this.dataURLtoBlob(compressedDataUrl);
      } catch (error) {
        console.error('Failed to compress image:', error);
        throw error;  // Ensure the error is propagated
      }
    },
    loadImageFile(file) {
      return new Promise((resolve, reject) => {
        loadImage(
          file,
          (canvas) => {
            if (canvas.type === 'error') {
              console.error('Error loading image');
              reject('Failed to load image');
            } else {
              resolve(canvas);
            }
          }, {
            maxWidth: 500,
            canvas: true,
            orientation: true,
          },
        );
      });
    },
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]);
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
      const buffer = new ArrayBuffer(byteString.length);
      const intArray = new Uint8Array(buffer);
      for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
      }
      return new Blob([
        buffer,
      ], { type: mimeString });
    },
    deleteImage(index) {
      this.imageUrls.splice(index, 1);
      this.selectedFiles.splice(index, 1);
      // If necessary, update the emitted value
      const compressedFiles = this.selectedFiles.map(file => this.compressImage(file));
      Promise.all(compressedFiles).then(compressed => {
        this.$emit('input', this.multiple ? compressed : compressed[0]);
      });
    },
  },
  async created () {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        const isOnMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        if (!isOnMobile) {
          this.hasValidCamera = false;
          return;
        }
        const devices = await navigator.mediaDevices.enumerateDevices();
        const hasCamera = devices.some(device => device.kind === 'videoinput');;
        this.hasValidCamera = hasCamera;
      } catch (error) {
        this.hasValidCamera = false;
      }
    } else {
      this.hasValidCamera = false;
    }
  },
};
</script>
