<template>
  <div>
    <v-switch
      v-model="internalValue.hasPermission"
      @change="handleCheck"
      hide-details="auto"
      :label="permissionLabel"
      :disabled="disabled" />
      <div v-if="partitionBySchool && internalValue.hasPermission">
        <v-checkbox
          v-model="internalValue.always"
          :disabled="disabled"
          dense
          hide-details="auto"
          label="Enable for all schools" />
        <school-select
            v-if="!internalValue.always"
            v-model="internalValue.forSchools"
            :disabled="disabled"
            class="mt-1"
            dense
            hide-details="auto"
            label="Specify schools"
            multiple />
      </div>
      <v-divider class="my-1" />
    </div>
</template>

<script>
import SchoolSelect from '../common/SchoolSelect.vue';
export default {
  components: { SchoolSelect },
  props: {
    partitionBySchool: {
      type: Boolean,
      default: false,
    },
    permissionLabel: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
    },
    editUserId: {
      type: String,
      required: false,
    },
    editUserRoleSchoolIds: {
      type: Array,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      internalValue: null,
      rules: {
        required: value => Boolean(value) || 'This field is required',
      },
    }
  },
  methods: {
    handleCheck(isChecked) {
      this.internalValue.always = isChecked && (!this.partitionBySchool || !this.editUserRoleSchoolIds)
      if (!isChecked) {
        this.internalValue.forSchools = this.editUserRoleSchoolIds || []
      }
    },
    setInternalValue() {
      if (!this.value) {
        if (this.partitionBySchool) {
          this.internalValue = {
            hasPermission: false,
            always: !this.editUserRoleSchoolIds,
            forSchools: this.editUserRoleSchoolIds || [],
          }
        } else {
          this.internalValue = {
            hasPermission: false,
            always: false,
          }
        }

        return
      }
      this.internalValue = JSON.parse(JSON.stringify(this.value))
    },
  },
  watch: {
    internalValue: {
      handler () {
        this.$emit('input', this.internalValue)
      },
      deep: true,
    },
    editUserId () {
      this.setInternalValue()
    },
    'value.internalKey' () {
      this.setInternalValue()
    },
  },
  created () {
    this.setInternalValue()
  },
}
</script>