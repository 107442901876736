var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-select',_vm._g(_vm._b({attrs:{"value":_vm.value,"items":_vm.observationCategories
      .map((observationCategory) => ({
        text: observationCategory.label,
        value: observationCategory.id,
      }))
      .sort((a, b) => {
        if (a.text === 'Other' && b.text === 'Other') return 0;
        if (a.text === 'Other') return 1;
        if (b.text === 'Other') return -1;
        return a.text.localeCompare(b.text);
      }),"clearable":"","dense":"","hide-details":"auto","label":"Category","outlined":""}},'v-select',_vm.$attrs,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }