<template>
<v-dialog :value="value" @input="$emit('input', $event)" max-width="528px">
    <v-card>
        <v-card-title> Start new SchoolDog Walk </v-card-title>
        <v-card-subtitle> Enter a few details to get started. </v-card-subtitle>
        <v-card-text>
        <school-select
            v-if="value"
            v-model="walkData.schoolId"
            :permission="user.permissions['create walks']" />
        <v-checkbox v-model="walkData.hasCollaborators" hide-details="auto" label="I will be collaborating with other SchoolDog users during this SchoolDog Walk." />
        <div v-if="walkData.hasCollaborators">
            <users-select
                v-model="walkData.collaborators"
                multiple
                :valid-user-ids="getEligibleCollaboratorsForWalk(walkData).map(user => user.value)"
                dense
                label="Collaborators"
                class="mt-2" />
        </div>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)" color="grey darken-1" text> Cancel </v-btn>
        <v-btn
            @click="createWalk()"
            :disabled="!isWalkValid || isCreatingWalk"
            color="primary"
        >
            Start SchoolDog Walk
        </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import Walk from '../../models/Walk'
import Rubric from '@/models/Rubric'
import SchoolSelect from '../common/SchoolSelect.vue'
import UsersSelect from '../common/UsersSelect.vue'
export default {
  components: {
    SchoolSelect,
    UsersSelect,
  },
  name: 'CreateWalkDialog',
  props: {
    value: Boolean,
  },
  data () {
    return {
      isCreatingWalk: false,
      walkData: {
        schoolId: null,
        hasCollaborators: false,
        collaborators: [],
      },
    }
  },
  computed: {
    ...mapGetters('app', [
      'getEligibleCollaboratorsForWalk',
      'getRubricById',
    ]),
    ...mapState('app', [
      'isOffline',
      'organizationSettings',
      'user',
      'walks',
      'rubrics',
    ]),
    isWalkValid () {
      return Boolean(this.walkData.schoolId)
    },
  },
  methods: {
    ...mapMutations('app', [
      'setWalks',
    ]),
    resetWalkData () {
      this.walkData = {
        schoolId: null,
        hasCollaborators: false,
        collaborators: [],
      }
    },
    async createWalk() {
      this.isCreatingWalk = true;
      try {
        let walk = new Walk()
        walk.collaborators = this.walkData.hasCollaborators ? (this.walkData.collaborators || []) : []
        walk.timeframe = this.walkData.timeframe
        walk.schoolId = this.walkData.schoolId
        walk.observations = []
        walk.observationsById = {}
        walk.assessedCategories = []
        walk.createdByUserId = this.user.id
        walk.leaderUserId = this.user.id
        if (this.user.isDistrictUser()) {
          walk.walkType = 'district'
        } else if (this.user.roleSchoolIds.includes(walk.schoolId)) {
          walk.walkType = 'school'
        } else {
          walk.walkType = 'partner'
        }
        walk.intendedCategories = []
        walk.status = 'inprogress';
        walk.stage = 'context'
        walk.shouldSendNeighborhoodWatch = false
        walk.hasSentNeighborhoodWatch = false
        walk.rubrics = [];
        this.rubrics.forEach(rubric => {
          if (!rubric.enabled || (rubric.districtWalksOnly && !walk.walkType === 'district')) {
            return;
          }
          const rubricCopy = { ...rubric };
          if (rubricCopy.skipByDefault) {
            rubricCopy.skip = true;
          }
          walk.rubrics.push(rubricCopy)
        });
        walk = await walk.save()
        if (!this.isOffline) {
          walk.collaborators.forEach(userId => {
            this.runFunction('sendCollaboratorEmail', {
              newCollaboratorUserId: userId,
              walkId: walk.id,
            });
          });
        }
        this.setWalks([
          ...this.walks,
          walk,
        ])
        this.$router.push(`/walks/walk/${walk.id}/inprogress`);
      } catch (e) {
        console.warn('Error creating new walk', e);
        /// TODO
      } finally {
        this.isCreatingWalk = false;
      }
    },
  },
  watch: {
    value () {
      if (this.value) {
        this.resetWalkData()
      }
    },
  },
}
</script>
