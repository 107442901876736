<template>
  <v-dialog
    @input="$emit('input', false)"
    :value="value"
    max-width="528px"
  >
    <v-card>
      <v-card-title>  Manage Access </v-card-title>
      <v-card-subtitle>
        Configure who has access to the data in this SchoolDog Walk
      </v-card-subtitle>
      <v-card-text>
        <div v-if="'inprogress' === walk.status">
          <div>
            <span>While a SchoolDog Walk is In Progress, the only users who can view or edit the data are the <glossary-term text="Leader" /> and the <glossary-term text="Collaborators" />. Only the <glossary-term text="Leader" /> can give additional users access.</span>
          </div>
          <div class="mt-1">
            <span>You are currently the <glossary-term text="Leader" />. You can make one of your <glossary-term text="Collaborators" /> the new <glossary-term text="Leader" />, but you will not be able to undo that action.</span>
          </div>
          <users-select
            v-model="collaborators"
            multiple
            :label="'Collaborators'"
            :disabled="isSubmitting"
            class="mt-2"
            dense
            hide-details="auto"/>
          <div v-if="collaborators && collaborators.length">
            <v-checkbox v-model="isChangingLeader" hide-details="auto" label="Change Leader" />
            <div v-if="isChangingLeader">
              <i>NOTE: If you change the Leader, you will no longer be able to manage access for this SchoolDog Walk, but you will remain a Collaborator.</i>
                <v-select
                  v-model="leaderId"
                  outlined
                  :items="leaderOptions"
                  dense
                  persistent-hint
                  class="mb-1 mt-2"
                  hide-details="auto"
                  label="New Leader"></v-select>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)" color="grey darken-1" text>
          Cancel
        </v-btn>
        <v-btn
          @click="save()"
          :disabled="isSubmitting"
          color="primary"
        >
          Save Changes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { uid } from 'uid';
import { mapGetters, mapState } from 'vuex';
import UsersSelect from '../common/UsersSelect.vue';
import GlossaryTerm from '../common/GlossaryTerm.vue';

export default {
  name: 'ManageWalkAccessDialog',
  components: {
    UsersSelect,
    GlossaryTerm,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    walk: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      collaborators: [],
      leaderId: null,
      isChangingLeader: false,
      isSubmitting: false,
    }
  },
  computed: {
    ...mapState('app', [
      'user',
      'usersById',
    ]),
    leaderOptions () {
      return [
        {text: `${this.user.firstName} ${this.user.lastName} (You)`, value: this.user.id },
      ].concat(this.collaborators.map(userId => this.usersById[userId])
        .filter((user) => {
          return Boolean(user)
        }).map(user => ({
          text: `${user.firstName} ${user.lastName} (${user.email})`,
          value: user.id,
        })).sort((a, b) => a.text.localeCompare(b.text)));
    },
  },
  methods: {
    async save() {
      const newCollaborators = this.collaborators.filter(userId => !this.walk.collaborators.includes(userId));
      this.walk.collaborators = this.collaborators;
      if (this.isChangingLeader) {
        this.walk.collaborators.push(this.walk.leaderUserId)
        this.walk.leaderUserId = this.leaderId;
        const leaderUser = this.usersById[this.leaderId];
        if (leaderUser.isDistrictUser()) {
          this.walk.walkType = 'district'
        } else if (leaderUser.roleSchoolIds.includes(this.walk.schoolId)) {
          this.walk.walkType = 'school'
        } else {
          this.walk.walkType = 'partner'
        }
        this.walk.collaborators.splice(this.walk.collaborators.indexOf(this.leaderId), 1);
      }
      this.isSubmitting = true;
      await this.walk.save();
      newCollaborators.forEach(userId => {
        this.runFunction('sendCollaboratorEmail', {
          newCollaboratorUserId: userId,
          walkId: this.walk.id,
        });
      });
      this.isSubmitting = false;
      this.$emit('input', false);
    },
  },
  watch: {
    value: {
      handler () {
        if (this.value) {
          this.collaborators = JSON.parse(JSON.stringify(this.walk.collaborators));
          this.leaderId = this.walk.leaderUserId;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
