<template>
  <v-select
    v-bind="$attrs"
    v-if="organizationSettings"
    @input="$emit('input', $event)"
    :value="value"
    :items="schoolOptions"
    dense
    hide-details="auto"
    outlined
    :label="label || (!multiple ? 'School' : 'Schools')"
    :multiple="multiple"
  ></v-select>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    defaultToFirstValue: Boolean,
    // value is string or array
    value: [
      String,
      Array,
    ],
    label: String,
    multiple: Boolean,
    permission: Object,
    // pass in a user permission that may be school-partitioned as a shortcut to determine valid school ids
  },
  computed: {
    ...mapGetters('app', [
      'schools',
    ]),
    ...mapState('app', [
      'organizationSettings',
      'user',
    ]),
    schoolOptions () {
      let validSchoolIds = null;
      if (this.permission) {
        validSchoolIds = this.permission?.always ? null : this.permission?.forSchools
      }
      return this.schools.filter(school => !validSchoolIds || validSchoolIds.includes(school.id)).map((school) => {
        return {
          value: school.id,
          text: school.name,
        }
      }).toSorted((a, b) => a.text.localeCompare(b.text))
    },
  },
  watch: {
    organizationSettings: {
      handler () {
        if (this.organizationSettings && !this.value && (this.defaultToFirstValue || this.schoolOptions.length === 1)) {
          this.$emit('input', this.schoolOptions[0]?.value)
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
