<template>
  <v-card @click="$emit('click')">
    <v-container>
      <div style="display: flex">
        <div class="text-subtitle-1 mb-1" style="flex: 1">
          <span v-if="walk.timeArchived" class="error--text">[Archived]</span> {{ getSchoolById(walk.schoolId).name }}
          <div class="text-caption">
            {{ numObservations }} observation entr{{ numObservations === 1 ? 'y' : 'ies' }}
          </div>
        </div>
        <v-menu v-if="!isOffline" left nudge-top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list tile>
            <v-list-item>
              <v-btn @click="$emit('enter')" text>
                <v-list-item-title>Open SchoolDog Walk</v-list-item-title>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="canManageWalk">
              <v-btn v-if="!walk.timeArchived" @click="$emit('archive')" text>
                <v-list-item-title>Archive SchoolDog Walk</v-list-item-title>
              </v-btn>
              <v-btn v-if="walk.timeArchived" @click="$emit('unarchive')" text>
                <v-list-item-title>Unarchive SchoolDog Walk</v-list-item-title>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="'inprogress' !== walk.status && canManageWalk">
              <v-btn @click="isPlaceWalkInProgressDialogVisible = true" text>
                <v-list-item-title>Place Back In Progress</v-list-item-title>
              </v-btn>
            </v-list-item>
            <v-list-item v-if="canManageWalk">
              <v-btn @click="isDeleteWalkDialogVisible = true" text color="red">
                <v-list-item-title>Permanently Delete</v-list-item-title>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Status
        </div>
        <div class="font-weight-medium">
          {{ statusLabels[walk.status] }}
        </div>
      </div> -->
      <div v-if="tags.length" class="mb-2">
        <div class="d-flex">
            <v-tooltip v-for="(tag, index) in tags" :key="index" bottom max-width="300px">
              <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small class="mr-1" :color="tag.color" text-color="white">
                    <div class="text-subtitle-2 font-weight-medium">{{ tag.text }}</div>
                  </v-chip>
              </template>
              {{ tag.tooltip }}
            </v-tooltip>
        </div>
      </div>
      <div v-if="walk.awaitingReviewBy && walk.status === 'review'" class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Awaiting Review By
        </div>
        <div class="font-weight-medium">
          <user-link :user-id="walk.awaitingReviewBy" />
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Leader
        </div>
        <div class="font-weight-medium">
          <user-link v-if="usersById[this.walk.leaderUserId]" :user-id="walk.leaderUserId" />
          <div v-else>Unknown</div>
        </div>
      </div>
      <div v-if="walk.collaborators && walk.collaborators.length" class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Collaborators
        </div>
        <div class="d-flex">
          <div
            v-for="userId in walk.collaborators"
            :key="userId"
            class="pr-1">
            <user-avatar
              :user-id="userId"
              size="28"></user-avatar>
          </div>
          <v-spacer></v-spacer>
        </div>
      </div>
      <div class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Time Started
        </div>
        <div class="font-weight-medium">
          {{ walk.timeCreated | formatDate('M/D/YYYY h:mma') }}
        </div>
      </div>
      <div
        v-if="walk.timeCompleted"
        class="text-body-2 mb-1">
        <div class="font-weight-thin text-caption">
          Time Completed
        </div>
        <div class="font-weight-medium">
          {{ walk.timeCompleted | formatDate('M/D/YYYY h:mma') }}
        </div>
      </div>
    </v-container>
    <v-dialog v-model="isDeleteWalkDialogVisible" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Are you sure?
        </v-card-title>
        <v-card-subtitle>
          Are you sure you want to delete this SchoolDog Walk from {{ walk.timeCreated | formatDate('M/D/YYYY') }}? This cannot be undone.
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="!isSubmitting"
          @click="isDeleteWalkDialogVisible = false"
          color="grey darken-1"
          text
          >No, Do Not Delete
          </v-btn>
          <v-btn
          @click="isSubmitting = true; $emit('delete');"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          color="primary"
          >Yes, Delete
          </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isPlaceWalkInProgressDialogVisible" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Are you sure?
        </v-card-title>
        <v-card-subtitle>
          Are you sure you want to place this SchoolDog Walk from {{ walk.timeCreated | formatDate('M/D/YYYY') }} back in progress?
        </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                v-if="!isSubmitting"
              @click="isPlaceWalkInProgressDialogVisible = false"
              color="grey darken-1"
              text
              >Cancel
              </v-btn>
              <v-btn
              @click="isSubmitting = true; $emit('set-in-progress');"
              :disabled="isSubmitting"
              :loading="isSubmitting"
              color="primary"
              >Yes, Continue
              </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import moment from 'moment'
import { mapState } from 'vuex'
import Walk from '@/models/Walk'
import UserLink from '../common/UserLink.vue'
import UserAvatar from '../common/UserAvatar.vue';
export default {
  components: {
    UserAvatar,
    UserLink,
  },
  name: 'WalkSummaryCard',
  props: {
    organizationSettings: Object,
    walk: Walk,
  },
  data() {
    return {
      isDeleteWalkDialogVisible: false,
      isPlaceWalkInProgressDialogVisible: false,
      isSubmitting: false,
      statusLabels: {
        review: 'In Review',
        inprogress: 'In Progress',
        complete: 'Complete',
      },
    };
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'user',
      'usersById',
    ]),
    canManageWalk () {
      if (this.walk.leaderUserId === this.user.id) {
        return true
      }
      if ('school' === this.walk.walkType && this.user.canAtSchool('manage walks', this.walk.schoolId)) {
        return true
      }
      if ([
        'district',
        'partner',
      ].includes(this.walk.walkType) && this.user.can('manage other walks')) {
        return true
      }
      return false;
    },
    leader () {
      return this.usersById[this.walk.leaderUserId] ? `${this.usersById[this.walk.leaderUserId].firstName} ${this.usersById[this.walk.leaderUserId].lastName}` : 'Unknown'
    },
    numObservations () {
      return Object.values(this.walk.observationsById).filter(observation => !observation.isDeleted).length
    },
    tags () {
      const tags = []
      if ('inprogress' === this.walk.status && Math.abs(moment(this.walk.timeUpdated).diff(moment(), 'days')) >= 7) {
        tags.push({
          text: 'Abandoned',
          color: '#dc4d01',
          tooltip: `This SchoolDog Walk has not been updated in more than 7 days. Consider archiving it or reminding ${this.leader} to complete it.`,
        })
      }
      
      if (this.walk.walkType === 'district') {
        tags.push({
          text: 'District-Led',
          color: 'blue darken-3',
          tooltip: 'This SchoolDog Walk is led by a District-level user.',
        })
      } else if (this.walk.walkType === 'partner') {
        tags.push({
          text: 'Partner-Led',
          color: 'blue',
          tooltip: `This SchoolDog Walk is led by a Partner from outside the school.`,
        })
      } else if (this.walk.walkType === 'school') {
        tags.push({
          text: 'School-Led',
          color: 'green',
          tooltip: `This SchoolDog Walk is led by a user from ${this.getSchoolById(this.walk.schoolId).name}.`,
        })
      }

      return tags
    },
  },
  methods: {
    getSchoolById (id) {
      if (this.organizationSettings && this.organizationSettings.schools) {
        return this.organizationSettings.schools.find(school => school.id === id) || {}
      }
      return {}
    },
  },
};
</script>
<style lang="scss">
</style>