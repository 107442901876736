<template>
  <v-app>
    <!-- Layout component -->
    <component :is="currentLayout" v-if="isRouterLoaded && !isInMisMatchedAuthState">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </component>

    <div v-else-if="twoHundredMillisecondsHavePassed" style="width: 100vw; height: 100vh" class="d-flex justify-center align-center flex-column">
      <img :src="require('@/assets/images/logo.png')" style="width: 120px;" />
       <v-progress-linear
          indeterminate
          color="primary"
          style="width: 112px"
          class="router-progress" />
        <div v-if="fourSecondsHavePassedSinceAuthMismatch && isInMisMatchedAuthState" class="text-center text-subtitle-1 mt-2" style="max-width: 320px">
          It is taking longer than expected to load your account. If your network connection is not stable, consider reopening SchoolDog in offline/airplane mode and then syncing your data later.
        </div>
    </div>

    <v-snackbar v-model="toast.show" :timeout="toast.timeout" :color="toast.color" bottom>
      <span class="text-body-1">{{ toast.message }}</span>
      <v-btn v-if="toast.timeout === 0" color="white" text @click="toast.show = false">{{ $t('common.close') }}</v-btn>
    </v-snackbar>

    <v-dialog v-model="isOfflineDialogVisible" max-width="528px">
      <v-card>
        <v-card-title>
          You have gone offline.
        </v-card-title>
        <v-card-text>
          Most elements of SchoolDog will continue to work until you reconnect, and you may continue to record observation entries. However, please note the following:
          <ul>
            <li>If you are using SchoolDog from a browser, do NOT attempt to refresh until you regain connection.</li>
          </ul>
          <v-checkbox
            v-model="hideDisclaimer"
            label="Do not show this disclaimer again on this device.">
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeOfflineDisclaimer()" color="primary">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <refresh-window-prompt />
    <pwa-install-prompt></pwa-install-prompt>
  </v-app>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'

import config from './configs'

import ImageUploadService from '@/services/ImageUploadService'

// Layouts
import defaultLayout from './layouts/DefaultLayout'
import simpleLayout from './layouts/SimpleLayout'
import landingLayout from './layouts/LandingLayout'
import authLayout from './layouts/AuthLayout'
import errorLayout from './layouts/ErrorLayout'
import PwaInstallPrompt from './components/common/PwaInstallPrompt.vue'
import RefreshWindowPrompt from './components/common/RefreshWindowPrompt.vue'

import TrackInactivity from '@/mixins/TrackInactivity'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default {
  components: {
    defaultLayout,
    simpleLayout,
    landingLayout,
    authLayout,
    errorLayout,
    PwaInstallPrompt,
    RefreshWindowPrompt,
  },
  mixins: [
    TrackInactivity,
  ],
  data () {
    return {
      hideDisclaimer: false,
      isOfflineDialogVisible: false,
      twoHundredMillisecondsHavePassed: false,
      fourSecondsHavePassedSinceAuthMismatch: false,
      setMismatchPromptPromise: null,
    }
  },
  computed: {
    ...mapState('app', [
      'user',
      'isOffline',
      'toast',
      'auth',
    ]),
    ...mapGetters('app', [
      'globalAppSettings',
    ]),
    isRouterLoaded: function() {
      if (this.$route.name !== null) return true

      return false
    },
    isInMisMatchedAuthState () {
      if (!this.auth) {
        return false
      }
      return !this.user && Boolean(this.auth.currentUser)
    },
    currentLayout: function() {
      const layout = this.$route.meta.layout || 'default'

      return layout + 'Layout'
    },
  },
  watch: {
    globalAppSettings: {
      handler() {
        this.maintenanceRouting()
      },
      deep: true,
    },
    isInMisMatchedAuthState () {
      if (this.isInMisMatchedAuthState) {
        this.fourSecondsHavePassedSinceAuthMismatch = false
        this.setMismatchPromptPromise = setTimeout(() => {
          this.fourSecondsHavePassedSinceAuthMismatch = true
        }, 4000)
      } else {
        if (this.setMismatchPromptPromise) {
          clearTimeout(this.setMismatchPromptPromise)
        }
        this.fourSecondsHavePassedSinceAuthMismatch = false
      }
    },
  },
  methods: {
    ...mapActions('app', [
      'setUserFromFirebaseUser',
    ]),
    ...mapMutations('app', [
      'setIsOffline',
    ]),
    maintenanceRouting() {
      const onMaintenancePage = this.$route.name == 'utility-maintenance';
      if (this.globalAppSettings.maintenanceMode && !onMaintenancePage) {
        this.$router.push('/utility/maintenance')
      } else if (!this.globalAppSettings.maintenanceMode && onMaintenancePage) {
        this.$router.push('/');
      }
    },
    handleOnline() {
      this.setIsOffline(false)
      this.setUserFromFirebaseUser()
      ImageUploadService.resumeFileUploads()
    },

    handleOffline() {
      this.setIsOffline(true)
      if (!localStorage.getItem('hideOfflineDisclaimer')) {
        this.isOfflineDialogVisible = true;
      }
    },

    closeOfflineDisclaimer () {
      if (this.hideDisclaimer) {
        localStorage.setItem('hideOfflineDisclaimer', '1');
      }
      this.isOfflineDialogVisible = false;
    },
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href })),
    ],
  },
  created() {
    if (!navigator.onLine) {
      this.handleOffline();
    }
    // Add event listeners when the component is created
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  },

  mounted () {
    window.setInterval(() => {
      if (!this.isOffline) {
        ImageUploadService.resumeFileUploads()
      }
    }, 30000);
    if (!this.isOffline) {
      ImageUploadService.resumeFileUploads()
    }

    setTimeout(() => {
      this.twoHundredMillisecondsHavePassed = true
    }, 200)
    setTimeout(() => {
      this.fourSecondsHavePassedSinceAuthMismatch = true
    }, 2000)
  },

  beforeDestroy() {
    // Remove event listeners before the component is destroyed
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  },
}
</script>

<style scoped>
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
