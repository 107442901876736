<template>
  <v-tooltip bottom :disabled="!isDisabled">
    <template v-slot:activator="{ on }">
      <div v-if="isItemValid(menuItem)" v-on="on">
        <v-list-item
          v-if="!menuItem.items"
          :input-value="menuItem.value"
          :to="menuItem.link"
          :exact="menuItem.exact"
          :disabled="!menuItem.worksOffline && isOffline"
          active-class="primary--text"
          link
        >
          <v-list-item-icon>
            <v-icon :small="small" :class="{ 'grey--text': !menuItem.worksOffline && isOffline }">
              {{ menuItem.icon || 'mdi-circle-medium' }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ menuItem.text }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon v-if="!menuItem.worksOffline && isOffline">
            <v-icon :small="small" color="error">
              mdi-wifi-off
            </v-icon>
          </v-list-item-icon>
        </v-list-item>

        <v-list-group
          v-else
          :value="menuItem.regex ? menuItem.regex.test($route.path) : false"
          :disabled="menuItem.disabled"
          :sub-group="subgroup"
          :to="menuItem.link"
          link
        >

          <template v-slot:activator>
            <v-list-item-icon v-if="!subgroup">
              <v-icon :small="small">{{ menuItem.icon || 'mdi-circle-medium' }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ menuItem.key ? $t(menuItem.key) : menuItem.text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>

          <slot></slot>

        </v-list-group>
      </div>
    </template>
    <span>{{ menuItem.key ? $t(menuItem.key) : menuItem.text }} cannot be used while offline.</span>
  </v-tooltip>
</template>

<script>
import { mapState } from 'vuex'
/*
|---------------------------------------------------------------------
| Navigation Menu Item Component
|---------------------------------------------------------------------
|
| Navigation items for the NavMenu component
|
*/
export default {
  props: {
    menuItem: {
      type: Object,
      default: () => {},
    },
    subgroup: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'user',
    ]),
    isDisabled () {
      return !this.menuItem.worksOffline && this.isOffline
    },
  },
  methods: {
    isItemValid (item) {
      if (item.isForUnauthedUsers && !this.user) {
        return true
      } else if (item.isForUnauthedUsers) {
        return false
      }
      if (!this.user) {
        return false
      }
      if (this.user.isSchoolDogStaff) {
        return true
      }
      if (item.requireSchoolDogStaff) {
        return false
      }
      if (item.permissions) {
        return this.$getResolvedPermission(item.permissions)
      }
      return true
    },
  },
}
</script>
