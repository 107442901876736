<template>
  <div class="progress-nav-bar">
    <div
      :class="{ 'active-step': value === 1 }"
      class="step-container"
      style="flex: 0"
    >
      <div
        @click="$emit('input', 0)"
        :class="['step-circle', getCircleClass(0)]"
      >
        <template v-if="value < 1">{{ 1 }}</template>
        <template v-else-if="value === 0">
          <span class="number">{{ 1 }}</span>
        </template>
        <template v-else>
          <v-icon class="check-icon" color="white">{{
            getCircleClass(0) === "warning" ? "mdi-alert" : "mdi-check"
          }}</v-icon>
        </template>
      </div>
      <div
        @click="$emit('input', 0)"
        class="step-label text-body-1"
      >
        Record
      </div>
    </div>
    <div class="step-container" :class="{ 'active-step': value === 2 }">
      <div class="step-line" :class="{ inactive: value === 0 }"></div>
      <div
        @click="$emit('input', 1)"
        :class="['step-circle', getCircleClass(1)]"
      >
        <template v-if="value < 1">{{ 2 }}</template>
        <template v-else-if="value === 1">
          <span class="number">{{ 2 }}</span>
        </template>
      </div>
      <div
        @click="$emit('input', 1)"
        class="step-label text-body-1"
        :style="value < 1 ? 'color: gray' : ''"
      >
        Review
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EditWalk',
  props: {
    value: Number,
    walk: Object,
  },
  methods: {
    getCircleClass(stepNumber) {
      if (this.value < stepNumber) return 'inactive';
      else if (this.value === stepNumber) return 'active';
      else if (stepNumber === 0 && !(Object.values(this.walk.observationsById || {}).filter(o => !o.isDeleted).length)) return 'warning'
      // else if (stepNumber === 0 && (!this.walk.context.weatherTemperature || !this.walk.context.weatherPrecipitation)) return 'warning'
      else return 'completed';
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-nav-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.step-container {
  display: flex;
  align-items: center;
  flex: 1;
}

.step-circle {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--v-primary-base);
  color: var(--v-primary-base);
  transition: background-color 0.3s;
}

.step-label {
  cursor: pointer;
  margin-left: 4px;
  color: var(--v-primary-base);
  font-weight: 500;
}

/* When current step is the active step */
.step-circle.active {
  background-color: var(--v-primary-base);
  color: white;
}

.step-circle.warning {
  background-color: var(--v-warning-dark);
  color: white;
}

.step-circle.inactive {
  border: 2px solid gray;
  color: gray;
}

/* When the step is completed */
.step-circle.completed {
  background-color: var(--v-primary-base);
  color: white;
}

.check-icon {
  font-size: 18px;
}

.number {
  font-weight: bold;
}

.step-line {
  flex: 1;
  height: 2px;
  background: var(--v-primary-base);
  margin: 0 8px;
}

.step-line.inactive {
  background: gray;
}
</style>
