<template>
  <v-select
    v-bind="$attrs"
    :value="value"
    :items="processedUsers"
    class="wrap-text-dropdown"
    item-value="id"
    :item-text="itemText"
    :multiple="multiple"
    outlined
    @change="updateValue"
    @input="updateValue"
  >
    <template v-slot:selection="{ index, item }">
      <span v-if="!multiple">
        {{ itemText(item) }}
      </span>
      <span v-else-if="value.length > 1 && index === 0">
        <!-- Show a count of additional selections -->
        {{ value.length }} users
      </span>
      <span v-else-if="value.length === 1">
        {{ itemText(item) }}
      </span>
    </template>
  </v-select>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
export default {
  props: {
    value: {
      type: [
        Array,
        String,
      ],
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showSelf: {
      type: Boolean,
      default: false,
    },
    validUserIds: {
      type: Array,
      required: false,
    },
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
      'schools',
    ]),
    ...mapState('app', [
      'user',
      'users',
    ]),
    processedUsers() {
      const users = this.validUserIds
        ? this.users.filter(user => this.validUserIds.includes(user.id))
        : this.users;
      const filteredUsers = users.filter(user => this.showSelf || (user.id !== this.user.id))
      const grouped = filteredUsers.reduce((acc, user) => {
        if (user.isDistrictUser() && !user.isSchoolDogStaff) {
          acc.district = acc.district || [];
          acc.district.push(user);
        } else if ((user.roleSchoolIds || []).length > 1) {
          acc.multiple = acc.multiple || [];
          acc.multiple.push(user);
        } else if ((user.roleSchoolIds || []).length === 1) {
          acc[user.roleSchoolIds[0]] = acc[user.roleSchoolIds[0]] || [];
          acc[user.roleSchoolIds[0]].push(user);
        } else if (user.isSchoolDogStaff) {
          acc.schoolDogStaff = acc.schoolDogStaff || [];
          acc.schoolDogStaff.push(user);
        }else {
          acc.other = acc.other || [];
          acc.other.push(user);
        }
        return acc;
      }, {});

      const result = [];
      Object.keys(grouped).sort((a, b) => {
        if (a === 'district') {
          return -1;
        }
        if (b === 'district') {
          return 1;
        }
        if (a === 'multiple') {
          return -1;
        }
        if (b === 'multiple') {
          return 1;
        }
        if (a === 'schoolDogStaff') {
          return 1;
        }
        if (b === 'schoolDogStaff') {
          return -1;
        }
        if (a === 'other') {
          return 1;
        }
        if (b === 'other') {
          return -1;
        }
        return this.getSchoolById(a).name.localeCompare(this.getSchoolById(b).name);
      }).forEach(schoolId => {
        if (!grouped[schoolId].length) {
          return;
        }
        const header = schoolId === 'district' ? 'District' : schoolId === 'multiple' ? 'Multiple Schools' : schoolId === 'schoolDogStaff' ? 'SchoolDog Staff' : schoolId === 'other' ? 'Other Users' : this.getSchoolById(schoolId).name;
        result.push({ header, schoolId: schoolId });
        grouped[schoolId].sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)).forEach(user => {
          result.push({ ...user, itemText: `${user.firstName} ${user.lastName}` });
        });
      });
      return result;
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value);
    },
    isSelected(item) {
      return this.value.some(selectedItem => selectedItem.id === item.id);
    },
    itemText(item) {
      return `${item.firstName} ${item.lastName} (${item.email})`
    },
    toggleSelection(item) {
      const index = this.value.findIndex(selectedItem => selectedItem.id === item.id);
      if (index >= 0) {
        const newValue = [
          ...this.value,
        ];
        newValue.splice(index, 1);
        this.updateValue(newValue);
      } else {
        this.updateValue([
          ...this.value,
          item,
        ]);
      }
    },
  },
};
</script>