<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    :items="
      observationCategories
        .map((observationCategory) => ({
          text: observationCategory.label,
          value: observationCategory.id,
        }))
        .sort((a, b) => {
          if (a.text === 'Other' && b.text === 'Other') return 0;
          if (a.text === 'Other') return 1;
          if (b.text === 'Other') return -1;
          return a.text.localeCompare(b.text);
        })
    "
    clearable
    dense
    hide-details="auto"
    label="Category"
    outlined
  ></v-select>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: String,  // The id
  },
  computed: {
    ...mapState('app', [
      'observationSettings',
    ]),
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationOptionsByCategoryId() {
      const map = {};
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id]
          .map((observationItem) => ({
            text: observationItem.label,
            value: observationItem.id,
          }))
          .sort((a, b) => {
            if (a.text === 'Other' && b.text === 'Other') return 0;
            if (a.text === 'Other') return 1;
            if (b.text === 'Other') return -1;
            return a.text.localeCompare(b.text);
          });
      });
      return map;
    },
  },
}
</script>