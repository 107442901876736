<template>
  <v-expansion-panel>
    <v-expansion-panel-header>Permissions</v-expansion-panel-header>
    <v-expansion-panel-content v-if="editUser.isSchoolDogStaff">
      This user is a SchoolDog staff member and thus has all permissions.
    </v-expansion-panel-content>
    <v-expansion-panel-content v-else>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            SchoolDog Role
          </div>
          <div class="text-body-1" v-if="user.id !== editUser.id">
            Users can be assigned a Role to help articulate the way they will use SchoolDog. Each role has a set of standard permissions automatically associated with it. However, the user's permissions can be further customized below.
          </div>
          <div v-else class="text-body-1">
            You cannot change your own role.
          </div>
          <div v-if="user.id !== editUser.id">
            <a @click.prevent="isRoleInfoVisible = !isRoleInfoVisible" class="text-decoration-underline">
              {{ isRoleInfoVisible ? 'Hide' : 'Show' }} details about roles
            </a>
            <div v-if="isRoleInfoVisible" class="mt-1">
              <ul>
                <li>
                  <strong>SchoolDog Site Admin</strong> - For users who will be overseeing and managing SchoolDog for the entire district. Full access to all features and permissions in SchoolDog, including the ability to edit and create users. If this user creates a SchoolDog Walk, it will be considered "district-led."
                </li>
                <li>
                  <strong>District Safety Leader</strong> - For users who will be actively using SchoolDog at a district level. Access to all SchoolDog features needed for a user, but without the ability to create users or manage the SchoolDog instance itself. If this user creates a SchoolDog Walk, it will be considered "district-led."
                </li>
                <li>
                  <strong>School Safety Leader—Primary</strong> - For users who will be actively using SchoolDog for a specific school. These leaders have the ability to manage SchoolDog Walks created by other School Safety Leaders as well as their own.
                </li>
                <li>
                  <strong>School Safety Leader</strong> - For users who will be actively using SchoolDog for a specific school. Access to create SchoolDog Walks and Tasks, but not the ability to manage SchoolDog Walks created by others.
                </li>
                <li>
                  <strong>School Resource Officer</strong> - For users who create SchoolDog Walks at one or more schools, but do not have access to downloadable reports, other users' walks, or other administrative features.
                </li>
                <li>
                  <strong>Custodian</strong> - For users whose only use of SchoolDog will be to receive tasks and translate them into a different maintenance work order system.
                </li>
                <li>
                  <strong>Teacher</strong> - For users whose will participating in SchoolDog Walks and proposing tasks, but not viewing other data or being proposed tasks.
                </li>
                <li>
                  <strong>Administrative Assistant</strong> - For users whose only use of SchoolDog will be to receive tasks and translate them into a different maintenance work order system.
                </li>
                <li>
                  <strong>Maintenance Work Order Manager</strong> - For users whose only use of SchoolDog will be to receive tasks and translate them into a different maintenance work order system.
                </li>
              </ul>
            </div>
          </div>
          <v-select
            v-model="editUser.role"
            :disabled="editUser.id === user.id"
            :items="roleOptions"
            dense
            label="Role"
            class="mt-1"
            hide-details="auto"
            outlined
            required
            :rules="[rules.required]" />
          <school-select
            v-if="shouldRoleSchoolIdsBeVisible"
            :disabled="editUser.id === user.id"
            class="mt-1"
            label="School(s)"
            v-model="editUser.roleSchoolIds"
            clearable
            multiple />
          <v-btn
            v-if="canSetDefaultPermissions && editUser.id !== user.id"
            color="primary"
            text
            class="mt-1"
            @click="setDefaultPermissions()">
            Set default permissions for Role
          </v-btn>            
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            SchoolDog Walk/Observation Entry Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['create walks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Start new SchoolDog Walks" />
          <edit-permission
            v-model="editUser.permissions['collaborate on walks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Collaborate on other users' SchoolDog Walks" />
          <edit-permission
            v-model="editUser.permissions['send neighborhood watch']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel='Send "Neighborhood Watch" alerts when completing SchoolDog Walks (recommended only for district-level users)' />
          <edit-permission
            v-model="editUser.permissions['review walks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Review SchoolDog Walks when requested" />
          <edit-permission
            v-model="editUser.permissions['view completed walks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="View other users' completed SchoolDog Walks" />
          <edit-permission
            v-model="editUser.permissions['download walk reports']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Download reports for completed SchoolDog Walks (requires access to view completed SchoolDog Walk)" />
          <edit-permission
            v-model="editUser.permissions['manage walks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Manage (view, archive, delete, place back in progress) other users' school-led SchoolDog Walks." />
          <edit-permission
            v-model="editUser.permissions['manage other walks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Manage (view, archive, delete, place back in progress) other users' district-led and partner-led SchoolDog Walks." />
          <edit-permission
            v-model="editUser.permissions['view observation leaderboard']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="View the observation entry leaderboards on the dashboard" />
          <edit-permission
            v-model="editUser.permissions['view data insights']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Access the Data Insights page" />
          <edit-permission
            v-model="editUser.permissions['download data insights reports']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Download reports from the Data Insights page (requires access to the Data Insights page)" />
          <edit-permission
            v-model="editUser.permissions['view cycles']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Access the Cycles page (for all schools)" />
          <edit-permission
            v-model="editUser.permissions['manage cycles']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Manage Cycle goals (for all schools)" />
        </v-container>
      </v-card>
      <v-card class="mb-2">
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Tasks Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['create tasks from observations']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Create tasks from observation entries" />
          <edit-permission
            v-model="editUser.permissions['create blank tasks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Create tasks from My Tasks page" />
          <edit-permission
            v-model="editUser.permissions['be proposed tasks']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Be proposed tasks by others" />
        </v-container>
      </v-card>
      <v-card>
        <v-container>
          <div class="text-subtitle-2 mb-1">
            Administrative Permissions
          </div>
          <edit-permission
            v-model="editUser.permissions['manage users']"
            :disabled="editUser.id === user.id"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Manage and create users" />
          <edit-permission
            v-model="editUser.permissions['download administrative reports']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Download administrative reports" />
          <edit-permission
            v-model="editUser.permissions['manage district']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            permissionLabel="Edit district information (name, supt, etc)" />
          <edit-permission
            v-model="editUser.permissions['manage schools']"
            :edit-user-id="editUser.id"
            :edit-user-role-school-ids="shouldRoleSchoolIdsBeVisible ? editUser.roleSchoolIds : null"
            partition-by-school
            permissionLabel="Edit school information" />
        </v-container>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex'
import SchoolSelect from '../common/SchoolSelect.vue'
import EditPermission from './EditPermission.vue'
export default {
  components: { EditPermission, SchoolSelect },
  name: 'EditUserPermissionsExpansionPanel',
  props: {
    editUser: Object,
  },
  data() {
    return {
      isRoleInfoVisible: false,
      rules: {
        required: value => Boolean(value) || 'This field is required',
      },
    }
  },
  computed: {
    ...mapState('app', [
      'user',
    ]),
    roleOptions () {
      return [
        {
          text: 'SchoolDog Site Admin',
          value: 'schooldog_site_admin',
        },
        {
          text: 'District Safety Leader',
          value: 'district_safety_leader',
        },
        {
          text: 'School Safety Leader—Primary',
          value: 'school_safety_leader',
        },
        {
          text: 'School Safety Leader',
          value: 'school_safety_team',
        },
        {
          text: 'School Resource Officer',
          value: 'school_resource_officer',
        },
        {
          text: 'Custodian',
          value: 'custodian',
        },
        {
          text: 'Teacher',
          value: 'teacher',
        },
        {
          text: 'Administrative Assistant',
          value: 'administrative_assistant',
        },
        {
          text: 'Maintenance Work Order Manager',
          value: 'maintenance_work_order_manager',
        },
      ]
    },
    shouldRoleSchoolIdsBeVisible() {
      return [
        'school_resource_officer',
        'school_safety_leader',
        'school_safety_team',
        'maintenance_work_order_manager',
        'custodian',
        'administrative_assistant',
        'teacher',
      ].includes(this.editUser.role)
    },
    canSetDefaultPermissions() {
      if (!this.editUser.role) {
        return false
      }

      if ([
        'schooldog_site_admin',
        'district_safety_leader',
      ].includes(this.editUser.role)) {
        return true
      }

      if ([
        'school_safety_leader',
        'school_safety_team',
        'school_resource_officer',
        'custodian',
        'administrative_assistant',
        'teacher',
        'maintenance_work_order_manager',
      ].includes(this.editUser.role) && this.editUser.roleSchoolIds?.length) {
        return true
      }

      return false
    },
    permissions () {
      return [
        {
          value: 'create walks',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'teacher',
          ],
        },
        {
          value: 'collaborate on walks',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'teacher',
          ],
        },
        {
          value: 'send neighborhood watch',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
        },
        {
          value: 'review walks',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
          ],
        },
        {
          value: 'view completed walks',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
          ],
        },
        {
          value: 'download walk reports',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
          ],
        },
        {
          value: 'manage walks',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
          schoolRoles: [
            'school_safety_leader',
          ],
        },
        {
          value: 'manage other walks',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
        },
        {
          value: 'view observation leaderboard',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
          ],
        },
        {
          value: 'view data insights',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
          ],
        },
        {
          value: 'download data insights reports',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
          ],
        },
        {
          value: 'view cycles',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
        },
        {
          value: 'manage cycles',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
        },
        {
          value: 'create tasks from observations',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'teacher',
          ],
        },
        {
          value: 'create blank tasks',
          alwaysRoles: [
            'schooldog_site_admin',
            'district_safety_leader',
          ],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'custodian',
            'administrative_assistant',
            'teacher',
          ],
        },
        {
          value: 'be proposed tasks',
          alwaysRoles: [],
          schoolRoles: [
            'school_safety_leader',
            'school_safety_team',
            'school_resource_officer',
            'maintenance_work_order_manager',
            'custodian',
            'administrative_assistant',
          ],
        },
        {
          value: 'manage users',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
        },
        {
          value: 'download administrative reports',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
        },
        {
          value: 'manage district',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
          schoolRoles: [],
        },
        {
          value: 'manage schools',
          alwaysRoles: [
            'schooldog_site_admin',
          ],
          schoolRoles: [
            'school_safety_leader',
          ],
        },
      ]
    },
  },
  methods: {
    setDefaultPermissions() {
      if (!this.editUser.role) {
        return
      }

      this.permissions.forEach((permission) => {
        if (permission.alwaysRoles && permission.alwaysRoles.includes(this.editUser.role)) {
          this.$set(this.editUser.permissions, permission.value, {
            hasPermission: true,
            always: true,
            internalKey: (this.editUser.permissions[permission.value]?.internalKey || 0) + 1,
          })
        } else if (permission.schoolRoles && permission.schoolRoles.includes(this.editUser.role)) {
          this.$set(this.editUser.permissions, permission.value, {
            hasPermission: true,
            always: false,
            forSchools: this.editUser.roleSchoolIds,
            internalKey: (this.editUser.permissions[permission.value]?.internalKey || 0) + 1,
          })
        } else {
          this.$set(this.editUser.permissions, permission.value, {
            hasPermission: false,
            always: false,
            forSchools: [],
            internalKey: (this.editUser.permissions[permission.value]?.internalKey || 0) + 1,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
